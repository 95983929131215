const CA =`\
-----BEGIN CERTIFICATE-----
QhqpDgefoya2SU+BKtE+eQU2FsdGVkX1/EuVR/OeIKAtTH6snVDZ/OB+i3yjgfPZCnwefpR2NpLghjTSfwiSQum/Y+weKdwNNiU9krzT7j5x7fxSleJnloaAPfHGxcY8TjUUHyh5XHqhU5CpZhIPT/VSDicgzpE7pAEn+xm1seDbFCz/VoSWH+hFNH+o6g1iA=
zxzhEub0PfQ/ODefIRCXYQU2FsdGVkX1/KVFH4ob86QFiaZD1wdlfelwDhExKFup1+xg61zBl8Sd8aWkFoCXyefr0zlgaJuyL7givNWfytqnIu830W1twKl+yKsxzdbJKhljsbqMcv/VhEdAZHtKdQLn5RjajKSTRuQcx56ydsoUdwsJ94PSDvcGVaIqVcXXM=
/9lnHVRMafKl4DCn+TbGTQU2FsdGVkX1/K1dzqEPHX+qHhWpFtvsQHuH1U77NjA/2dXappIpckUYR+YEQMALoU2imr4a+yy9d1HoM1IQ5WL4cEnHs3x9gdScKvphRk8kBU0E1Iq82efGPLpQSz8KvUYT+aSeCjyolxTGg/cuXHAaEZr9qvKkg6UXzQcMjY7fk=
Jp/DZc2Yy5CwYHTptisENgU2FsdGVkX1/1qyv0YoHEqM0g10VmivqAxPGlRZIPbqgyfQTsNipT8utzDeB7okjTqexoHscR7nOH81MuGbGZqeWLRNinMiMeftEPO6ncmgfx80b/c+fB1uNpiWP5Z459UM7x1zHxDk+Jv99GH8jmYAg74iUo5KriOkTi4SMARlk=
-----END CERTIFICATE-----`;

// "localhost",
// appkey "5a2364b5-7b38-fd6f-1f43-77c9a51a1f53",
// appsecret "5a2364b5-7b38-fd6f-53ba-555ae359a928",
// ds "1234567890abcdef"

const Settings = {
    simulation: false,
    tk: 'jutoken',
    ah: '/v2', // for portal  rw
    ak: ['please', 'contact', 'joe#wzhu.net'].join('-'), 
    as: ['for', 'a', 'license'].join('-'),
    ds: ['all', 'rights', 'reserved'].join('-'),
}

const AMapSettings = {
    key: "8210f1c05ea676120e284a84c1106a1c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0",                          // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    token: '_AMap_Token',
    // plugins: ['AMap.AutoComplete'],
    // plugins:['AMap.ToolBar','AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
};

const Enums = {
    'user_types'         :[{value:0, label:"请刷新以重新加载"}], //用户类型
    'auth_types'         :[{value:0, label:"请刷新以重新加载"}], //账户类型
    "event_types"        :[{value:0, label:"请刷新以重新加载"}], //活动类型
    "ticket_types"       :[{value:0, label:"请刷新以重新加载"}], //票务类型
    "institution_types"  :[{value:0, label:"请刷新以重新加载"}], //机构类型
    "institution_levels" :[{value:0, label:"请刷新以重新加载"}], //机构级别 
    'collaborator_levels':[{value:0, label:"请刷新以重新加载"}], //合作伙伴级别
    "folder_tags"        :[{value:0, label:"请刷新以重新加载"}], //文件夹标签
    "entry_statuses"     :[{value:0, label:"请刷新以重新加载"}], //作品状态
    "entry_levels"       :[{value:0, label:"请刷新以重新加载"}], //作品级别
    "entry_groups"       :[{value:0, label:"请刷新以重新加载"}], //作品赛道
}

const Categories = {
    'entry' :  [{value:0, label:"请刷新以重新加载"}],
};


export { Settings, AMapSettings, CA, Enums, Categories };
